export const USER = '/user'
export const CORPORATIONS = '/corporations'
export const LOCATIONS = '/locations'
export const PAYMENT_SETTINGS = '/paymentSettings'
export const MONTHLY_PARKING = '/monthlyParking'
export const INTEGRATIONS = '/integrations'
export const TRANSACTION = '/transaction'

// NOTE:  USER - authorization
export const LOGIN = `${USER}/login`
export const REFRESH_TOKEN = `${USER}/refreshtoken`
export const LOGOUT = `${USER}/logout`
export const GET_LINK_TO_LOG_TEZ_AS_RES_OWNER = `${USER}/getLinkToLogTezAsResOwner`

export const GET_REDIRECT_URL_TO_NACHA_PORTAL = `${USER}/getRedirectUrlToNachaPortal`

// NOTE:  CORPORATIONS
export const GET_CORPORATIONS_LIST = CORPORATIONS
export const GET_CORPORATION_NAME = `${CORPORATIONS}/getName`

// NOTE:  LOCATIONS
export const GET_LOCATIONS_LIST = LOCATIONS
export const GET_LOCATION_NAME = `${LOCATIONS}/getName`

// NOTE: AREA
export const GET_AREA = '/getArea'

// NOTE:  PAYMENT_SETTINGS
export const GET_PAYMENT_CONFIG = PAYMENT_SETTINGS
export const CHANGE_CONFIGURATION_SOURCE = `${PAYMENT_SETTINGS}/changeConfigurationSource`
export const SAVE_PAYMENT_CONFIG = `${PAYMENT_SETTINGS}/saveMonthlyConfiguration`

// NOTE: MONTHLY_CONFIGURATION
export const GET_MONTHLY_CONFIGURATION = `${MONTHLY_PARKING}/getMonthlyConfiguration`
export const SAVE_MONTHLY_CONFIGURATION = `${MONTHLY_PARKING}/saveMonthlyConfiguration`

// NOTE: INTEGRATION_CONFIGURATION
export const VERIFY_T2_CONNECTION_INTEGRATION_SETTINGS = `${INTEGRATIONS}/verifyT2IntegrationSettings`
export const VERIFY_T2_V2_CONNECTION_INTEGRATION_SETTINGS = `${INTEGRATIONS}/verifyT2IntegrationV2Settings`
export const GET_INTEGRATION_MODULES_SETTINGS = `${INTEGRATIONS}/getIntegrationModulesSettings`
export const SAVE_INTEGRATION_MODULES_SETTINGS = `${INTEGRATIONS}/saveIntegrationModulesSettings`

// NOTE: MONTHLY_TRANSACTIONS
export const GET_MONTHLY_PARKING_TRANSACTIONS = `${MONTHLY_PARKING}/getMonthlyParkingTransactions`
export const MAKE_CHARGEBACK_TRANSACTION = `${TRANSACTION}/makeChargebackTransaction`

//TIMER
const TIME_TO_LOGOUT_PRODUCTION = 900_000
const PROMPT_BEFORE_IDLE_PRODUCTION = 60_000
// const TIME_TO_LOGOUT_TEST = 45_000
// const PROMPT_BEFORE_IDLE_TEST = 15_000
export const TIME_TO_LOGOUT = TIME_TO_LOGOUT_PRODUCTION
export const PROMPT_BEFORE_IDLE = PROMPT_BEFORE_IDLE_PRODUCTION
