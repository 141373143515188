import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { PROMPT_BEFORE_IDLE, TIME_TO_LOGOUT } from '../../api/constants'
import { TimerClock } from './styles'
import TimerWarningPopup from './TimerWarningPopup'

interface TimerProps {
  logout: () => void
  automaticLogout: () => void
}

const Timer = ({ logout, automaticLogout }: TimerProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(0)
  const [openTimerWarningPopUp, setOpenTimerWarningPopup] = useState(false)

  const onIdle = () => {
    setOpenTimerWarningPopup(false)
    automaticLogout()
  }

  const onPrompt = () => {
    setOpenTimerWarningPopup(true)
  }

  const timer = useIdleTimer({
    onIdle,
    onPrompt,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    timeout: TIME_TO_LOGOUT,
    throttle: 500,
    events: ['mousedown', 'keydown'],
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(timer.getRemainingTime() / 1000))
    }, 500)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (remainingTime === TIME_TO_LOGOUT / 1000 && openTimerWarningPopUp) {
      setOpenTimerWarningPopup(false)
    }
  }, [remainingTime, openTimerWarningPopUp])

  const handleStillHere = () => {
    setOpenTimerWarningPopup(false)
    timer.reset()
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds
    return `${formattedMinutes}:${formattedSeconds}`
  }

  return (
    <>
      <TimerClock id={'timer'}>{formatTime(remainingTime)}</TimerClock>
      <TimerWarningPopup
        show={openTimerWarningPopUp}
        onCancel={logout}
        onOkClick={handleStillHere}
        remainingTime={formatTime(remainingTime)}
      />
    </>
  )
}

export default Timer
